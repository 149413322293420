import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Icon, Text } from "@lipihipi/rtc-ui-components";
import React, { FC, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { themeVars } from "../../theme";
import { Logout, Menu, Settings } from "@mui/icons-material";
import { AppContext } from "../../App";
import RtcAPI from "@lipihipi/rtc-client-sdk";
import { SITE_URL } from "../../constant";
import { PROFILE, SETTING } from "../../RouteConstant";

interface ISidebar {
  toggle: boolean;
  setToggle: Function;
  logoUrl: string;
  menuArr: any[];
}

export const Sidebar: FC<ISidebar> = ({
  toggle,
  setToggle,
  logoUrl,
  menuArr,
}) => {
  const [open, setOpen] = React.useState("");
  const location = useLocation();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const classes = {
    root: {
      backgroundColor: themeVars.primaryStrong,
      flexShrink: 0,
      padding: "20px",
      // height: "100vh",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      // transition: 'all ease-in-out .3s',
    },

    header: {
      flexShrink: 0,
      height: "50px",
      paddingBottom: "9px",
      display: "flex",
      alignItems: "center",
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        backgroundColor: themeVars.primaryStrong,
      },
    },

    body: {
      overflowY: "auto",
      overglowX: "hidden",
      margin: "0 -20px",
      padding: "0 20px",
      flexGrow: 1,
      "&::-webkit-scrollbar": { width: "5px" },
      "::-webkit-scrollbar-track": { background: "transparent" },
      "::-webkit-scrollbar-thumb": { background: "#5c504a" },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#5c504a",
      },
    },
  };

  const handleItemClick = (item: any) => {
    if (item.external) {
      window.open(item.link, "_blank");
    }
  };

  const logout = () => {
    RtcAPI.auth.logout({ appName: "AdminWeb" }).then((res: any) => {
      localStorage.clear();
      window.location.href = SITE_URL;
    });
  };

  return (
    <Box
      sx={{
        ...classes.root,
        width: toggle ? "16.875rem" : "64px",
        display: "flex",
        flexDirection: "column",
        transition: "all ease-in-out .3s",
        gap: 1,
      }}
    >
      <Box sx={classes.header}>
        {!toggle ? (
          <IconButton
            sx={{
              background: "transparent",
              color: "white",
              transition: "100ms ease all",
              ":hover": {
                background: "white",
                color: "black",
              },
            }}
            onClick={() => setToggle((prev: boolean) => !prev)}
          >
            <Menu />
          </IconButton>
        ) : (
          <img src={logoUrl} alt="logo" />
        )}
      </Box>

      <Box sx={classes.body}>
        {menuArr?.map((menu: any) => (
          <Box key={menu?.id}>
            <List
              component="nav"
              disablePadding
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflowX: "hidden",

                "& .MuiListItemButton-root": {
                  borderRadius: "6px",
                  padding: "6px 8px",
                  columnGap: "8px",
                  "& > svg": {
                    color: "#BDAF92",
                  },
                  "& .MuiListItemIcon-root": {
                    padding: "4px",
                    borderRadius: "4px",

                    "& svg": {
                      width: "16px",
                      height: "16px",
                      "& path": {
                        "&[fill]": {
                          fill: "#BDAF92",
                        },
                        "&[stroke]": {
                          stroke: "#BDAF92",
                        },
                      },
                      "& rect": {
                        "&[fill]": {
                          fill: "#BDAF92",
                        },
                        "&[stroke]": {
                          stroke: "#BDAF92",
                        },
                      },
                    },
                  },
                  "& .MuiListItemText-primary": {
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "14.4px",
                    color: "#F4F5F7",
                  },
                  "&::before": {
                    display: "none",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#F1E9D1",
                    "&:hover": {
                      backgroundColor: "#F1E9D1",
                    },
                    "& > svg": {
                      color: "#DC9D00",
                    },
                    "& .MuiListItemIcon-root": {
                      background: "#DC9D00",
                      "& svg": {
                        "& path": {
                          "&[fill]": {
                            fill: "#fff",
                          },
                          "&[stroke]": {
                            stroke: "#fff",
                          },
                        },
                        "& rect": {
                          "&[fill]": {
                            fill: "#fff",
                          },
                          "&[stroke]": {
                            stroke: "#fff",
                          },
                        },
                      },
                    },
                    "& .MuiListItemText-primary": {
                      color: "#393836",
                      fontWeight: 600,
                    },
                  },
                },
              }}
            >
              {menu?.data?.map((item: any, idx: number) => {
                const selected = location?.pathname === item?.link;
                if (item?.child) {
                  return (
                    <Box>
                      <ListItemButton
                        selected={open?.includes(item?.id)}
                        key={`list-button-${idx + 1}`}
                        onClick={() => {
                          if (!toggle) {
                            setToggle(true);
                          }
                          setOpen(() =>
                            open?.includes(item?.id) ? "" : item?.id
                          );
                        }}
                      >
                        <ListItemIcon>
                          <item.icon color="currentColor" />
                        </ListItemIcon>
                        {toggle && (
                          <>
                            <ListItemText primary={item?.text} />
                            <Icon.Right
                              color="currentColor"
                              style={{
                                transform: open?.includes(item?.id)
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                              }}
                            />
                          </>
                        )}
                      </ListItemButton>

                      {toggle && (
                        <Box px={item?.child?.length ? 3 : 0}>
                          <Collapse
                            in={open?.includes(item?.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List
                              sx={{
                                "& .MuiListItemButton-root": {
                                  padding: "10px 12px",
                                },
                              }}
                              component="div"
                            >
                              {item?.child?.map((child: any) => (
                                <ListItemButton
                                  selected={location?.pathname === child?.link}
                                  disabled={!child.active}
                                  component={Link}
                                  key={child?.id}
                                  to={child?.link}
                                  sx={{
                                    pl: 4,
                                    borderRadius: 2,
                                    "&.Mui-selected": {
                                      backgroundolor: "red",
                                      "& .MuiListItemText-primary": {
                                        fontWeight: 400,
                                      },
                                      "&:before": {
                                        display: "none",
                                      },
                                    },
                                  }}
                                >
                                  <ListItemText primary={child?.text} />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        </Box>
                      )}
                    </Box>
                  );
                }
                return (
                  <ListItemButton
                    component={item.external ? "div" : Link}
                    onClick={() => handleItemClick(item)}
                    to={item.external ? undefined : item?.link}
                    selected={selected}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#2D2723",
                        "& .MuiListItemText-primary": {
                          fontWeight: 400,
                        },
                      },
                    }}
                    disabled={!item.active}
                    key={item?.id}
                  >
                    <ListItemIcon>
                      <item.icon color={selected ? "currentColor" : "white"} />
                    </ListItemIcon>
                    {toggle && (
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: selected ? 500 : 200,
                        }}
                        sx={{ whiteSpace: "nowrap" }}
                        primary={item?.text}
                      />
                    )}
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        ))}
      </Box>

      <Box sx={{}}>
        <List
          component="nav"
          disablePadding
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowX: "hidden",

            "& .MuiListItemButton-root": {
              borderRadius: "6px",
              padding: "6px 8px",
              columnGap: "8px",
              "& .MuiListItemIcon-root": {
                padding: "4px",
                borderRadius: "4px",

                "& svg": {
                  width: "16px",
                  height: "16px",
                  color: "#BDAF92",
                },
              },
              "& .MuiListItemText-primary": {
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14.4px",
                color: "#F4F5F7",
              },
              "&::before": {
                display: "none",
              },
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
              "&.Mui-selected": {
                backgroundColor: "#F1E9D1",
                "&:hover": {
                  backgroundColor: "#F1E9D1",
                },
                "& .MuiListItemIcon-root": {
                  background: "#DC9D00",
                  "& svg": {
                    color: "#DC9D00",
                  },
                },
                "& .MuiListItemText-primary": {
                  color: "#393836",
                  fontWeight: 600,
                },
              },
            },
          }}
        >
          <ListItemButton
            onClick={logout}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#2D2723",
                "& .MuiListItemText-primary": {
                  fontWeight: 400,
                },
              },
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ whiteSpace: "nowrap" }} primary={"Log Out"} />
          </ListItemButton>
        </List>

        <Divider
          sx={{
            my: 2,
            background: "#878787",
          }}
        />

        <Box
          display="flex"
          gap={1.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(PROFILE)}
            display="flex"
            gap={1.5}
            alignItems="center"
          >
            <Avatar
              sx={{
                width: "2.625rem",
                height: "2.625rem",
              }}
              alt={`${state?.user?.firstName} ${state?.user?.lastName}`}
              src={
                state?.user?.image?.url ||
                "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fpluspng.com%2Fimg-png%2Fuser-png-icon-young-user-icon-2400.png&f=1&nofb=1&ipt=64a35864790504975bf2765d2eb373c809c08a88766a1660fe561f9b0d075fbe&ipo=images"
              }
            />
            <Box>
              <Text
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  lineHeight: "20px",
                  color: "#F4F5F7",
                }}
              >
                {`${state?.user?.firstName} ${state?.user?.lastName}`}
              </Text>

              <Text
                sx={{
                  fontSize: "10px",
                  lineHeight: "20px",
                  fontWeight: 400,
                  color: "#F4F5F7",
                }}
              >
                {`${state?.user?.email}`}
              </Text>
            </Box>
          </Box>
          {state.user.isSuperAdmin && (
            <IconButton
              onClick={() => navigate(SETTING)}
              sx={{
                background: "transparent",
                padding: 1,
                width: "auto",
                height: "auto",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <Settings sx={{ fontSize: "20px", color: "#BDAF92" }} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
